import React from "react";
import {getPublicUrl} from "../../helpers/getPublicUrl";

const YdaTests = () => {

  const downloadPDF = (fileName, name)=> () => {
    const publicUrl = getPublicUrl()
    const pdfUrl = publicUrl + `/dak_program/${fileName}`; // If stored in public
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", `${name}.pdf`); // Set download attribute
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dakPrograms = [
    {
      code: "60110200",
      name: "Maktabgacha ta’lim",
      link: "60110200_tests.pdf"
    },
    {
      code: "60112200",
      name: "Jismoniy madaniyat",
      link: "60112200_tests.pdf"
    },
    {
      code: "60111800",
      name: "Xorijiy til va adabiyoti (ing)",
      link: "60111800_en_tests.pdf"
    },
    {
      code: "60111800",
      name: "Xorijiy til va adabiyoti (ru)",
      link: "60111800_ru_tests.pdf"
    },
    {
      code: "5111700",
      name: "Boshlangʻich ta’lim",
      link: "5111700_tests.pdf"
    },
    {
      code: "5230100",
      name: "Iqtisodiyot",
      link: "5230100_tests.pdf"
    },
    {
      code: "5311900",
      name: "Neft va gaz ishi",
      link: "5311900_tests.pdf"
    }
  ]

  return (
    <div className="about__area about__area_one p-relative pt---100 pb---60">
      <div className="container">
        <div className="row">
          {dakPrograms.map(programs=> (
              <div className="col-lg-6 mb----30">
                  <span>{programs.code}</span>
                  <p className="lead m-0 text-black">{programs.name}</p>
                <button className="btn link-primary pl---0" onClick={downloadPDF(programs.link, programs.name)}>YDA testlarini yuklash</button>
                  {/*<a target="_blank" href={"../../assets/dak_program/60110200.pdf"} download type="application/pdf" rel="noopener noreferrer"></a>*/}
              </div>
          ))}
          {/*<div className="col-lg-6">*/}
          {/*  <p>IPU 1-101-xona</p>*/}
          {/*  <iframe width="560" height="315" src="https://www.youtube.com/embed/5sOar1cEttg?si=CgOH8PR4i0r5eBdz"*/}
          {/*          title="YouTube video player" frameBorder="0"*/}
          {/*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
          {/*          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
          {/*</div>*/}
          {/*<div className="col-lg-6">*/}
          {/*  <p>IPU 1-306-xona</p>*/}

          {/*  <iframe width="560" height="315" src="https://www.youtube.com/embed/hvft2GdDpoQ?si=F63l0eJyRUFhQ3G0"*/}
          {/*          title="YouTube video player" frameBorder="0"*/}
          {/*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
          {/*          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}

          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default YdaTests;
