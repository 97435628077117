import React from "react";
import Header from "../../components/Header";
import FooterTwo from "../../components/Footer/FooterTwo";
import Breadcrumb from "../../components/Breadcrumb";
import ScrollToTop from "../../components/ScrollTop";
import FinalExamMain from "./FinalExamMain";
import YdaTests from "./YdaTests";

import Logo from "../../assets/images/logos/new-logo1.png";
import { useIntl } from "react-intl";
import { useParams } from "react-router";

const FinalExamLive = () => {
  const intl = useIntl();
  const params = useParams();

  return (
    <>
      <Header
        parentMenu="page"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

      <div class="react-wrapper">
        <div class="react-wrapper-inner">
          <Breadcrumb pageTitle={intl.formatMessage({ id: params?.examType === "plans" ? "COMMON.YDA_PLANS" : "COMMON.YDA_TESTS" })} />

          {params.examType === "plans" ? <FinalExamMain /> : <YdaTests />}

          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <FooterTwo />
    </>
  );
};

export default FinalExamLive;
